<template>
	<div
		class="vx-row h-screen lg:flex w-full no-gutter"
		id="page-register"
		>
    <!-- cancel seat -->
    <div class="text-left vx-col h-full w-full lg:m-0 p-6">
			<div class="flex justify-center items-center h-full w-full">
				<div class="px-10 max-w-lg mx-auto login-wrapper w-full">
          <div class="max-w-lg">
            <div class="space-y-6">
              <div class="text-xl">
                {{ $t('Confirmation to release your seat reservation on') }}
                <div class="text-4xl">{{ event && event.name }}</div>
                <vs-button id="save-changes-homepage" @click="cancelSeat()" class="save-btn-settings mt-10 mr-6" >{{$t('Cancel My Seat Reservation')}}</vs-button>
                <vs-button id="save-changes-homepage" @click="backToEvent()" color="#797979" class="mt-10" >{{$t('Cancel')}}</vs-button>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import noLocale from '@/lib/locale/no';
import relativeTime from 'dayjs/plugin/relativeTime';
// import PreLiveEvent from '@/components/PreLiveEvent.vue';
import participantApi from '@/api/participant';
import { getAxiosErrorMessage } from '@/lib/helper';
import eventsApi from '@/api/event';

dayjs.locale('en');
dayjs.extend(relativeTime);

export default {
  props: {
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        company: '',
        is_attending_to_venue: false,
        notes: '',
      },
      isCancelling: false,
      event: null,
      eventSettings: null,
    };
  },
  components: {
    // PreLiveEvent,
  },
  created() {
    this.fetchEvent();
  },
  methods: {
    fetchEvent() {
      const callback = (response) => {
        this.event = response.data;
        this.eventSettings = this.event.settings;
      };
      const errorCallback = () => {
      };
      eventsApi.get(this.eventId, callback, errorCallback);
    },
    cancelSeat() {
      this.isCancelling = true;
      this.$vs.loading();
      const notifTitle = this.$t('Cancel Seat');
      const params = {
        email: this.email,
      };
      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isCancelling = false;
        this.$vs.loading.close();
        this.backToEvent();
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isCancelling = false;
        this.$vs.loading.close();
      };
      participantApi.cancelSeat(this.event.id, params, callback, errorCallback);
    },
    backToEvent() {
      const code = this.event && this.event.link_code_value ? this.event.link_code_value : null;
      this.$router.push(`/c/${code}`);
    },
  },
  computed: {
    formatEventTime() {
      if (this.userLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');

      const timeLabel = this.event && this.event.start_date ? dayjs(this.event.start_date).format('dddd DD MMMM YYYY') : '';
      return timeLabel;
    },
    isHybrid() {
      return this.event && this.event.is_hybrid ? this.event.is_hybrid : false;
    },
    isAttend() {
      return this.form.is_attending_to_venue;
    },
    isFormValid() {
      return this.form.name !== '' && this.form.email !== '';
    },
    email() {
      return this.$route.query.email;
    },
    eventId() {
      return this.$route.params.eventId;
    },

  },
  mounted() {
  },
  watch: {
    isAttend() {
      if (this.isAttend) this.form.notes = '';
    },
  },
};
</script>

<style lang="scss">
</style>
